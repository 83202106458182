<template>
  <!-- <div class="w-100 h-[80px]" /> -->
  
  <div
    class="fixed top-0 left-0 right-0 bg-secondary text-primary-content pt-[65px] p-1 z-[10] elevation-3 flex justify-center align-center"
    @click="productSelectorClicked()"
  >
    <v-btn
      variant="icon"
      icon="mdi-filter"
      class="mx-2"
    />

    <h2 class="">
      All Products
    </h2>
    <v-btn
      variant="icon"
      icon="mdi-filter"
      class="mx-2"
      style="color: oklch(var(--s));"
    />
  </div>
  <div v-if="isMenuVisible" class="pt-[25px] mb-[-50px] mx-4 relative">
    <v-item-group
      v-model="currTab"
      class="mt-[115px] font-extrabold glass w-screen py-2 elevation-3 fixed z-[4] left-0 right-0 top-0"
    >
      <template
        v-for="(top, idx) in tabs"
        :key="idx"
      >
        <NuxtLink
          v-if="top!=undefined"
          :to="'/shop/products/'+encodeURIComponent(top)"
        >
          <button
            class="w-full text-xl py-2 rounded-0"
          >
            <p class="hover:font-bold">
              {{ top }}
            </p>
          </button>
        </NuxtLink>
      </template>
    </v-item-group>
  </div>
  <!-- <v-menu activator="#menu-activator">
    <v-item-group
      v-model="currTab"
      class="mt-2 font-semibold glass w-screen py-2 elevation-3"
    >
      <template
        v-for="(top, idx) in tabs"
        :key="idx"
      >
        <NuxtLink
          v-if="top!=undefined"
          :to="'/shop/products/'+encodeURIComponent(top)"
        >
          <button
            class="w-full text-xl py-2 rounded-0"
          >
            <p class="hover:font-bold">
              {{ top }}
            </p>
          </button>
        </NuxtLink>
        <NuxtLink
          v-else
          to="/ohfuck"
        >
          Shit
        </NuxtLink>
      </template>
    </v-item-group>
  </v-menu> -->
</template>

<script setup>
const isMenuVisible = ref(false)
const currTab=ref('All')
const tabs = ref(['All'])
tabs.value = [
  'All',
  'Candles',
  'Mugs',
  'Puzzles',
  'Journals',
]

const productSelectorClicked = () => {
  isMenuVisible.value = !isMenuVisible.value
}
</script>

<style scoped>
.pacifico {
  font-family: 'Pacifico';
}
</style>